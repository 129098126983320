<template>
    <section class="oportunidades-listado-vigentes px-3 overflow-auto scroll-none">
        <titulo-divisor titulo="Lista de oportunidades en proceso">
            <div class="row">
                <div class="col-auto pl-1">
                  <button class="btn btn-general f-12 px-4" @click="crearOportunidad">Crear oportunidad</button>
                </div>
                <div class="col-auto pl-1">
                    <filter-indicator :count="contar" @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro" />
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="550px" layoutServidor="prev, pager, next, jumper" :usarServidor="true" :usarPaginacion="true" :servidorData="oportunidades" @paginar="listarOportunidades">
            <el-table-column label="ID" prop="id" align="center" width="60">
                <template slot-scope="scope">
                    <p class="f-12">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Oportunidad" prop="oportunidad">
                <template slot-scope="scope">
                    <el-tooltip :content="scope.row.oportunidad" placement="top-start">
                    
                        <p class="f-12 tres-puntos cr-pointer f-600" @click="verInforOp(scope.row.id)">
                            {{ scope.row.oportunidad }}
                        </p>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cotización" prop="cotizacion">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.cotizacion }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Equipos" prop="cantEquipos" align="center" width="90">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.equipos }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Ciclo actual" prop="cicloActual">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.ciclo_actual }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valorCotizado" align="center">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="probabilidad" prop="probabilidad" align="center">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.probabilidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de cierre" prop="fechaCierre" align="center" width="210">
                <template slot-scope="scope">
                    {{ scope.row.fecha_cierre ? scope.row.fecha_cierre: '-' }}
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modalCrearOportunidad ref="modalCrearOportunidad" />
        <modal-filtrar-op ref="filtrarOportunidad" @listar="listarOportunidades" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    components:{
        modalCrearOportunidad: () => import('./partials/modalCrearOportunidad'),
        modalFiltrarOp: () => import('./partials/modalFiltrarOp'),
    },
    computed: {
        ...mapGetters({
            filtros: 'oportunidades/oportunidades/filtros',
            dataFiltros: 'oportunidades/oportunidades/dataFiltros',
            oportunidades: 'oportunidades/oportunidades/oportunidades',
            numCriteriosFiltro: 'oportunidades/oportunidades/numCriteriosFiltro',
        }),
        contar(){
            return Number(this.filtros.count()) + this.numCriteriosFiltro
        }
    },
    async created(){
        const ruta = process.env.VUE_APP_URL_FULL_SMART;
        window.open(`${ruta}/oportunidades/en-proceso/listado`, "_self")
    },
    async destroyed () {
        await this.Action_delete_filtros();
    },
    methods:{
        ...mapActions({
            Action_clear_filtros: 'oportunidades/oportunidades/Action_clear_filtros',
            Action_delete_filtros: 'oportunidades/oportunidades/Action_delete_filtros',
            Action_get_oportunidades: 'oportunidades/oportunidades/Action_get_oportunidades',
            Action_get_filtros_oportunidades: 'oportunidades/oportunidades/Action_get_filtros_oportunidades',
        }),
        async listarOportunidades(page = 1){
            await this.Action_get_oportunidades({page: page, filtro: this.contar <= 0 ? undefined : 0})
        },
        crearOportunidad(){
            this.$refs.modalCrearOportunidad.toggle()
        },
        verInforOp(id){
            this.$router.push({ name: 'oportunidades.ver', params: {id, tipo:"vigentes"} })
        },
        async abrirModalFiltros(){
            await this.Action_get_filtros_oportunidades({tipo: 'vigentes'});
            this.$refs.filtrarOportunidad.toggle('vigentes')
        },
        async limpiarFiltro(){
            await this.$refs.filtrarOportunidad.cleanFilter()
        }
    },
}
</script>
<style lang="scss" scoped>
.oportunidades-listado-vigentes{
    height: calc(100vh - 225px);
}
</style>